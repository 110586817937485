import axios from "@/axios.js";
/* eslint-disable */
export default {
  LesCreatePreTrial(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/les`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  LesCreatePreTrialFormData(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/les`, item, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  LesPreTrialSearch(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?type=les` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  OpenLesPreTrial(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendFile(id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/les-trial/${id}`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetLES(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetLESHistory(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?type=les` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
// GetLESHistory(obj) {
//   return new Promise((resolve, reject) => {
//     Promise.all([
//       axios.get(`nexlaw?type=les` + obj),
//       axios.get(`nexlaw?type=les_submission` + obj)
//     ])
//     .then(([lesResponse, lesSubmissionResponse]) => {
//       const combinedData = {
//         les: lesResponse.data,
//         les_submission: lesSubmissionResponse.data
//       };
//       resolve(combinedData);
//     })
//     .catch((error) => {
//       reject(error);
//     });
//   });
// },

  sendQuestion(type, id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case/${type}/${id}/ask`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  finalSubmission(id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/final-submission/${id}`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  submitOngoing(campaignId, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/submit-ongoing-trial/${campaignId}`, item, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  LesFinalSubmission(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/les-submission`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  LesFinalSubmissionFormData(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/les-submission`, item, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};